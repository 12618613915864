import forEach from 'lodash/forEach';
import { HEADER_BACKGROUND_BREAKPOINTS } from './constants';

export const getImageForBreakpoint = ({
    windowWidth,
    imagesSet,
}: {
    windowWidth?: number;
    imagesSet: { [key: string]: any };
}) => {
    let imageUrl = imagesSet.mobile;

    if (windowWidth) {
        forEach(HEADER_BACKGROUND_BREAKPOINTS, (breakpointSize, keyName) => {
            if (windowWidth >= breakpointSize) {
                imageUrl = imagesSet[keyName];
            }
        });
    }

    return imageUrl;
};

const IMAGE_INTRINSIC_SIZES: { [key: string]: number } = {
    desktop: 1920,
    twok: 1924,
};

export const makeImageSetString = (imageSet?: { [key: string]: any }) => {
    const defaultImage = imageSet?.mobile;
    const imageSetKeys = Object.keys(imageSet || {});

    const setString = imageSetKeys.reduce((acc, key) => {
        const fileName = imageSet?.[key];
        const width = IMAGE_INTRINSIC_SIZES[key];

        if (!width) {
            return acc;
        }

        return (acc += `${fileName} ${width}w,`);
    }, '');

    return {
        default: defaultImage,
        srcSet: setString.slice(0, setString.length - 1),
    };
};
