import React from 'react';
import { connect } from 'react-redux';
import { DiscoverState } from '../../../../types/index';
import { HEADER_BACKGROUND_BREAKPOINTS } from './constants';
import './HeaderBackground.scss';
import { makeImageSetString } from './utils';

const SIZES = '(max-width: 1920px) 1920px, 1924px';

interface HeaderBackgroundProps {
    imageSet?: { [key: string]: any };
    headerImages?: object;
    placeholder?: { color?: string };
}

const MainHeaderBackgroundImageSet = ({
    imageSet,
    placeholder,
}: HeaderBackgroundProps) => {
    const formattedImageSet = makeImageSetString(imageSet);

    return (
        <>
            <source
                media={`(max-width: ${HEADER_BACKGROUND_BREAKPOINTS['tablet']}px)`}
                srcSet={imageSet?.['mobile']}
            />
            <source
                media={`(max-width: ${HEADER_BACKGROUND_BREAKPOINTS['desktop']}px)`}
                srcSet={imageSet?.['tablet']}
            />
            {
                <img
                    //HTML Img Definition doesnt support fetchpriority but it
                    //is supported in chrome and edge with broader support coming
                    //https://caniuse.com/mdn-html_elements_img_fetchpriority
                    //eslint-disable-next-line
                    //@ts-ignore
                    fetchpriority="high"
                    className="fullbleed-header__main-bg"
                    src={formattedImageSet.default}
                    alt="Homepage header"
                    srcSet={formattedImageSet.srcSet}
                    sizes={SIZES}
                    style={{ backgroundColor: placeholder?.color }}
                    loading="eager"
                />
            }
        </>
    );
};

export const FullbleedHeaderBackground = ({
    placeholder,
    headerImages,
}: HeaderBackgroundProps) => {
    return (
        <div className="fullbleed-header__main-bg-wrapper">
            <picture>
                <MainHeaderBackgroundImageSet
                    imageSet={headerImages || {}}
                    placeholder={placeholder || {}}
                />
            </picture>
        </div>
    );
};

const _mapStateToProps = ({ header }: DiscoverState) => ({
    headerImages: header.images,
});

export default connect(_mapStateToProps)(FullbleedHeaderBackground);
