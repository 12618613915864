import { LocationState } from '@eventbrite/discover-utils';
import { withOverlayControls } from '@eventbrite/eds-structure';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    DiscoverState,
    FeatureFlags,
    HeaderShape,
    UserShape,
} from '../../../../types';

import { HeaderTitle } from '../../components/HeaderTitle';
import { HomeEngagementExperimentTrackingLocations } from '../../constants/analytics';
import {
    EVENT_REELS_PLACES,
    EVENT_REELS_REDIRECT_URL,
} from '../../constants/eventreels';
import { clickCTASeasonal } from '../../redux/content/actions';
import { logToStatsigHomeEngagement } from '../../utils/analytics';
import { FullbleedHeaderBackgroundContainer } from '../FullbleedHeaderBackgroundContainer';
import './FullbleedHeader.scss';
interface FullbleedHeaderProps {
    location: LocationState;
    history?: object;
    header: HeaderShape;
    clickCTASeasonal?: Function;
    user: UserShape;
    isMobile: boolean;
    featureFlags: FeatureFlags;
}

export const FullbleedHeader = ({
    header,
    clickCTASeasonal,
    user,
    isMobile,
    location,
    featureFlags,
}: FullbleedHeaderProps) => {
    const [newHeader, setNewHeader] = React.useState<HeaderShape>(header);

    useEffect(() => {
        if (
            featureFlags?.launchAttendeeEventReels &&
            isMobile &&
            location?.placeId &&
            EVENT_REELS_PLACES.includes(location.placeId)
        ) {
            setNewHeader({
                ...header,
                customContentCtaLink: EVENT_REELS_REDIRECT_URL,
            });
        } else {
            setNewHeader(header);
        }
    }, [isMobile, header, location, featureFlags]);

    const handleHeaderCTASeasonalClick = () => {
        logToStatsigHomeEngagement(
            HomeEngagementExperimentTrackingLocations.CITY_CTA,
            !!user.isAuthenticated,
        );
        clickCTASeasonal?.();
    };

    const headerTitleComponent = (
        <HeaderTitle
            header={newHeader}
            clickCTASeasonal={handleHeaderCTASeasonalClick}
            fullbleed={true}
        />
    );

    return (
        <section className="feed-fullbleed-header">
            <div className="feed-fullbleed-header__wrapper">
                <FullbleedHeaderBackgroundContainer
                    type="MainHeaderBackground"
                    isSeasonal={header?.shouldShowCustomContent}
                    placeholder={header?.headerImagePlaceholder}
                />

                <div className="feed-fullbleed-header__content">
                    <div className="eds-layout eds-layout--has-large-max-width eds-layout--has-horizontal-gutters">
                        <div className="eds-layout__body">
                            {headerTitleComponent}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const _mapStateToProps = ({
    header,
    user,
    location,
    env,
    app,
}: DiscoverState) => ({
    header,
    user,
    location,
    isMobile: env.isMobile,
    featureFlags: app.featureFlags,
});

const _mapDispatchToProps = {
    clickCTASeasonal,
};

export default withOverlayControls(
    connect(_mapStateToProps, _mapDispatchToProps)(FullbleedHeader),
);
