export const EVENT_REELS_PLACE_ID: { [key: string]: string } = {
    NEW_YORK: '85977539',
    ORLANDO: '85933091',
    MIAMI: '85933669',
    TAMPA: '85931789',
    JACKSONVILLE: '85932547',
    SAN_DIEGO: '85922227',
    LOS_ANGELES: '85923517',
    SAN_FRANCISCO: '85922583',
    SAN_JOSE: '85922347',
};

export const EVENT_REELS_PLACES = [
    EVENT_REELS_PLACE_ID.NEW_YORK,
    EVENT_REELS_PLACE_ID.ORLANDO,
    EVENT_REELS_PLACE_ID.MIAMI,
    EVENT_REELS_PLACE_ID.TAMPA,
    EVENT_REELS_PLACE_ID.JACKSONVILLE,
    EVENT_REELS_PLACE_ID.SAN_DIEGO,
    EVENT_REELS_PLACE_ID.LOS_ANGELES,
    EVENT_REELS_PLACE_ID.SAN_FRANCISCO,
    EVENT_REELS_PLACE_ID.SAN_JOSE,
];

export const EVENT_REELS_REDIRECT_URL = '/event-reels';
